<!--商品展示，中型，占宽的1/2-->
<template>
    <div class="product-wrapper" @click="toDetail">
        <div class="product-img-wrapper" :style="imgStyle">
            <!--已售罄-->
            <div v-if="isSellOut == 1" class="product-grey-status-wrapper">
                <img class="product-status-img" src="https://seetop-1257860468.file.myqcloud.com/html/img/index_online002.png">
            </div>
            <!--已结束-->
            <div v-else-if="currentTimestamp > standardTime2Timestamp(onlineEnd)" class="product-grey-status-wrapper">
                <img class="product-status-img" src="https://seetop-1257860468.file.myqcloud.com/html/img/index_online003.png">
            </div>
            <!--即将上线-->
            <div class="product-seckill-time-wrapper" v-else-if="currentTimestamp < standardTime2Timestamp(onlineStart)">
                <img class="product-alarm-clock-icon" src="https://seetop-1257860468.file.myqcloud.com/html/img/index_icon20200915001.png">
                <div class="product-seckill-time">{{onlineStart}} 开抢</div>
            </div>
        </div>
        <div class="product-info-wrapper">
            <div class="product-name">{{name}}</div>
            <div class="product-price-wrapper">
                <div>￥{{moneyFen2Yuan(price)}}</div>
                <div>￥{{moneyFen2Yuan(orgPrice)}}</div>
            </div>
            <div class="product-distribute-price-wrapper" v-if="isDistributor == 1">
                <div>奖励金</div>
                <div>￥{{moneyFen2Yuan(distributorPrice)}}</div>
            </div>
        </div>
    </div>
</template>

<script>
  import { getCurrentTimestamp, moneyFen2Yuan, standardTime2Timestamp } from '../../utils/tools'

  export default {
    name: 'ProductMidSizeItem',
    props: ['id', 'cellId', 'isSellOut', 'onlineStart', 'onlineEnd', 'orgPrice', 'price', 'name', 'distributorPrice', 'img', 'isDistributor'],
    methods: {
      moneyFen2Yuan,
      standardTime2Timestamp,
      toDetail: function () {
        this.$router.push('/product/' + this.cellId)
        // window.location.href = 'https://club.seetop.net.cn/web/details.html?cell=' + this.cellId
      }
    },
    computed: {
      currentTimestamp: function () {
        return getCurrentTimestamp()
      },
      imgStyle: function () {
        const style = "background: url('https://seetop-1257860468.file.myqcloud.com/seetop/uploads/" + this.img + "') no-repeat;background-size: 100% 100%;"
        return style
      }
    },
    created () {
    }
  }
</script>

<style scoped>
    .product-wrapper {
        width: 46%;
        display: inline-block;
        margin: 0.7rem 0 0 0.7rem;
    }
    .product-img-wrapper {
        width: 100%;
        height: 30vw;
        position: relative;
    }
    .product-grey-status-wrapper {
        background-color: rgba(53,53,53,0.52);
        width: 100%;
        height: 100%;
    }
    .product-status-img {
        position: absolute;
        right: 0;
        top: 0;
        width: 5rem;
    }
    .product-seckill-time-wrapper {
        width: 100%;
        display: flex;
        flex-flow: row;
        align-items: center;
        background-color: #f1a417;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .product-alarm-clock-icon {
        width: 2rem;
    }
    .product-seckill-time {
        color: #ffffff;
        font-size: 0.6rem;
        /*margin-left: 1rem;*/
    }
    .product-info-wrapper {
        position: relative;
        background-color: #ffffff;
        box-sizing: border-box;
        padding: 0.5rem;
        box-shadow: 0 0 0.3rem #b9b9b9;
    }
    .product-name {
        min-height: 1.9rem;
        font-size: 0.75rem;
        overflow:hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
        color: #666666;
    }
    .product-price-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
        margin-top: 0.2rem;
        padding: 0.6rem 0 0.2rem 0;
    }
    .product-price-wrapper div:first-child {
        color: #fa1f1f;
        font-size: 0.9rem;
    }
    .product-price-wrapper div:nth-child(2) {
        color: #92909f;
        font-size: 0.7rem;
        text-decoration: line-through
    }
    .product-distribute-price-wrapper {
        width: 3.5rem;
        height: 2.3rem;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/index_icon20200915002.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        right: 0;
        bottom: 0.5rem;
        padding-top: 0.3rem;
        box-sizing: border-box;
    }
    .product-distribute-price-wrapper div {
        font-size: 0.6rem;
        color: #ffffff;
    }
</style>
