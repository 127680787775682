<!--分组页面-->
<template>
    <div>
        <!--banner-->
        <div class="top-wrapper">
            <img class="down-load-poster" src="https://seetop-1257860468.file.myqcloud.com/vue/img/dwonloadposter.png" v-if="isDistributionMember == 1 && groupConfig.poster" @click="viewPoster">
            <van-swipe class="swipe-wrapper" indicator-color="white" >
                <van-swipe-item>
                    <img @click="toAction" class="swipe-img" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + groupConfig['banner']">
                </van-swipe-item>
            </van-swipe>
        </div>
        <!--商品列表-->
        <van-list
                v-model="listLoading"
                :finished="listLoadingFinished"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <product-mid-size-item v-for="(item, index) in listLoadingList" :key="index"
                                   :isSellOut="item.is_sellout"
                                   :onlineStart="item.online_start"
                                   :onlineEnd="item.online_end"
                                   :orgPrice="item.price"
                                   :price="item.mallprice"
                                   :name="item.name"
                                   :distributorPrice="item.c_level1_money"
                                   :img="item.images[0]"
                                   :id="item.id"
                                   :isDistributor="isDistributionMember"
                                   :cellId="item.product_cell_id"
            ></product-mid-size-item>
        </van-list>
        <float-nav></float-nav>
        <img v-if="groupId == 82" @click="jumpChoujiang" src="https://seetop-1257860468.cos.ap-shanghai.myqcloud.com/vue/img/1212%E6%B4%BB%E5%8A%A8.png" class="position-img donghua">
    </div>
</template>

<script>
  import { globalConfig, listLoading, WXJsdk } from '../../utils/mixin'
  import ProductMidSizeItem from '../../components/product/ProductMidSizeItem'
  import { getChannelParams, getPostSrcChannel, getStationId, setShopTitle } from '../../utils/tools'
  import FloatNav from '../../components/common/FloatNav'
  import { getCookie } from '../../utils/cookie'

  export default {
    name: 'Groups',
    components: { FloatNav, ProductMidSizeItem },
    mixins: [globalConfig, listLoading, WXJsdk],
    data () {
      return {
        currentStation: 1,
        groupId: 1,
        isDistributionMember: 0,
        groupConfig: []
      }
    },
    methods: {
      init: function () {
        const that = this
        this.axios.post(this.apiUrl + 'mall/init/groupConfigV2', {
          groupId: this.groupId,
          srcChannel: getPostSrcChannel()
        }).then((response) => {
          const data = response.data
          const result = data.data
          if (data.status == 0) {
            this.$dialog.confirm({
              title: '提示',
              message: data.msg
            }).then(() => {
              that.$router.push('/home')
            }).catch(() => {
              that.$router.push('/home')
            })
          } else {
            that.WxShareConfig(result.shareInfo.title, result.shareInfo.desc, that.createDistributionShareUrl(window.location.href), 'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + result.shareInfo.imgUrl)
            that.groupConfig = result.group
            // 设置当前店铺名，只有一种可能；用户不是分销员，并且当前渠道存在有效；
            if (that.isDistributionMember == 0 && result.srcChannelShopName) {
              setShopTitle(result.srcChannelShopName)
            }
          }
        })
      },
      viewPoster: function () {
        this.$router.push('/salePoster?groupId=' + this.groupId)
      },
      jumpChoujiang: function () {
        window.location.href = 'https://club.seetop.net.cn/web/activity/lottery20211212/index.html'
      },
      toAction: function () {
        if (this.groupId == 94) {
          window.location.href = 'https://club.seetop.net.cn/web/activity/lottery603'
        }
      }
    },
    created () {
      this.currentStation = getStationId()
      getChannelParams()
      this.groupId = this.$route.params.id
      this.isDistributionMember = getCookie('isDistributionMember')
      this.init()
      this.listLoadingInit(this.apiUrl + 'mall/Product/getGroupProductList', 'list', 1, {
        keyword: '',
        group: this.groupId,
        stationId: this.currentStation
      })
    }
  }
</script>

<style scoped>
    .top-wrapper {}
    .down-load-poster {
        width: 3rem;
        height: 3rem;
        position: fixed;
        left: 1.5rem;
        top: 2rem;
        z-index: 99;
    }
    .swipe-wrapper {
        width: 95%;
        margin: 0.5rem auto 0;
    }
    .swipe-wrapper .van-swipe-item {
        width: 100%;
        color: #fff;
        font-size: 20px;
        text-align: center;
    }
    .swipe-img {
        width: 100%;
        border-radius: 1rem;
    }
    .position-img {
        position: fixed;
        right: 1rem;
        bottom: 4rem;
        display: flex;
        flex-flow: column;
        width: 3.5rem;
        height: 3.5rem;
    }
    .donghua {
        animation:mymove 3.5s infinite;
        /*-webkit-animation:mymove 5s infinite; !*Safari and Chrome*!*/
        /*animation-direction:alternate;!*轮流反向播放动画。*!*/
        /*animation-timing-function: ease-in-out; !*动画的速度曲线*!*/
        /*!* Safari 和 Chrome *!*/
        /*-webkit-animation:mymove 5s infinite;*/
        /*-webkit-animation-direction:alternate;!*轮流反向播放动画。*!*/
        /*-webkit-animation-timing-function: ease-in-out; !*动画的速度曲线*!*/
    }
    @keyframes mymove
    {
        0%{
            transform: scale(1);  /*开始为原始大小*/
        }
        25%{
            transform: scale(1.3); /*放大1.1倍*/
        }
        50%{
            transform: scale(1);
        }
        75%{
            transform: scale(1.3);
        }
    }
</style>
